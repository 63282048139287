<template>
<div class="inspect-index">
  <el-page-header @back="goBack" content="机构评估指标"></el-page-header>
  <div class="content" v-loading="loading">
    <div class="top">
      <div class="t1">
        <h3>托育机构质量评估</h3>
        <p>总分值：{{ score_all * 1 }}分</p>
      </div>
      <div class="t2">
        <h3>{{ org_name }}</h3>
        <!-- // 未提交 // 已提交-->
        <template v-if="inspectStatus == 1 || inspectStatus == 2">
          <!-- 机构 -->
          <template v-if="role == 4">
            <p>创建时间：{{inspect_status.created_time}}</p>
            <p>自评用时：{{inspect_status.total_time || '0时0分0秒'}}</p>
            <p>自评总分：{{inspect_status.score*1}}分</p>
            <p>自评进度：{{ratio}}</p>
          </template>
          <template v-if="role != 4">
            <p>提交时间：{{inspect_status.submit_date}}</p>
            <p>自评用时：{{inspect_status.total_time || '0时0分0秒'}}</p>
            <p>自评总分：{{inspect_status.score*1}}分</p>

          </template>
        </template>
        <template v-if="inspectStatus == 3 || inspectStatus == 4 ">
          <template v-if="isSchoolType">
            <p>提交时间：{{inspect_status.submit_date}}</p>
            <p>评审开始时间：{{inspect_status.examine_start_time}}</p>
            <p v-if="inspectStatus == 4">评审结束时间：{{inspect_status.examine_date}}</p>
            <p>自评用时：{{inspect_status.total_time || '0时0分0秒'}}</p>
            <p>自评总分：{{inspect_status.score*1}}分</p>
            <p style="color:#F04134;" v-if="isAdminUser">评审总分：{{inspect_status.examine_score*1}}分</p>
          </template>
          <template v-if="isAdminType">
            <p>评审开始时间：{{inspect_status.created_time}}</p>
            <p v-if="inspectStatus == 4">评审结束时间：{{inspect_status.examine_date}}</p>
            <p style="color:#F04134;">评审总分：{{inspect_status.examine_score*1}}分</p>
            <p v-if="inspectStatus == 3">他评进度：{{ratio}}</p>
          </template>
        </template>
      </div>
      <div class="t3">
        <p>
          本标准规定托育机构的办托条件、托育队伍、保育照护、卫生保健、养育支持、安全保障、机构管理等内容。
        </p>
        <p>
          本标准适用于为3岁以下婴幼儿提供全日托照护服务的托育机构。提供半日托、计时托、临时托等照护服务的托育机构可参照执行。
        </p>
      </div>
      {{ role }}
      <div class="t4" v-if="!readOnly && !queryReadonly">
        <el-button @click="goQuestionnaire" class="questionnaire2" type="primary" v-if="((role == 3 || role == 4) && questionnaire == null)">问卷调查</el-button>
        <el-button @click="goUp" :disabled="opinion.length != 0" class="questionnaire" type="primary" v-if="(role == 3 || role == 1 || role == 2 || role == 6)">改进建议</el-button>
        <!-- 非机构 提交评审-->
        <el-button @click="eximainRelease" :loading="submitLoading" class="questionnaire" type="primary" v-if="(role == 3 || role == 1 || role == 2 || role == 6)">提交</el-button>
       <!-- 机构 -->
        <el-button @click="schoolRelease" :loading="submitLoading" class="questionnaire" type="primary" v-if="role == 4">提交</el-button>
        <!-- <el-button @click="toList" :loading="submitLoading" class="questionnaire" type="primary">返回提交</el-button> -->
      </div>
    </div>
    <div class="bottom">
      <div class="card" @click="startInspect(item)" v-for="(item, index) of cardList" :key="index" :style="{background: 'url('+require('@/assets/index/'+(index+1)+'.png')+') no-repeat calc(100% - 25px) 26px'}">
        <div class="icon">
          <img :src="item.icon" alt="" />
        </div>
        <div class="msg">
          <h3>{{ item.name }}</h3>
          <div class="admin-inspect-card">
            <div v-if="item.object_pid == 7">
              <p>总项：</p>
              <p>通过项：</p>
              <p>通过比重：</p>
              <p>评估进度：</p>
            </div>
            <div v-if="item.object_pid != 7">
              <p>分值：</p>
              <p>得分：</p>
              <p>得分比重：</p>
              <p>评估进度：</p>
            </div>
            <div v-if="inspectStatus == 1 || inspectStatus == 2">
              <p>{{ item.score_all }}</p>
              <p>{{ item.score }}</p>
              <p>{{ item.score_ratio }}</p>
              <p>{{ item.total_ratio }}</p>
            </div>
            <template v-if="isSchoolType">
              <div v-if="inspectStatus == 3 || inspectStatus == 4">
                <p>{{ item.score_all }}</p>
                <p>{{ item.score }}</p>
                <p>{{ item.score_ratio }}</p>
                <p>{{ item.total_ratio }}</p>
              </div>
              <!-- && isAdminUser -->
              <div v-if="(inspectStatus == 3 || inspectStatus == 4) && isAdminUser || inspectStatus == 4 && !isAdminUser" style="color:#F04134;">
                <p>{{ item.score_all }}</p>
                <p>{{ item.examine_score }}</p>
                <p>{{ item.examine_score_ratio }}</p>
                <p>{{ item.examine_total_ratio }}</p>
              </div>
            </template>
            <template v-if="isAdminType">
              <div v-if="inspectStatus == 3 || inspectStatus == 4" style="color:#F04134;">
                <p>{{ item.score_all }}</p>
                <p>{{ item.score }}</p>
                <p>{{ item.score_ratio }}</p>
                <p>{{ item.total_ratio }}</p>
              </div>
            </template>
          </div>
        </div>
        <div class="btn">
          <span v-if="readOnly || queryReadonly">查看 ></span>
          <span v-else-if="isSchoolType && (inspectStatus == 3 || inspectStatus == 4) && isAdminUser && parseFloat(item.examine_total_ratio) > 0">继续评估 ></span>
          <span v-else-if="isAdminType && (inspectStatus == 3 || inspectStatus == 4) && parseFloat(item.total_ratio)>0">继续评估 ></span>
          <span v-else-if="!isAdminUser && parseFloat(item.total_ratio)>0">继续评估 ></span>
          <span v-else>去评估 > </span>
        </div>
      </div>
    </div>
  </div>
  <el-dialog title="改进建议" :visible.sync="upShow" @close="dialogClose" width="50%">
    <el-form :model="upForm" ref="upForm" label-width="0" style="width: 80%;display:block;margin:0 auto;">
      <el-form-item v-for="(content, index) in upForm.content" :key="index" :prop="'content.' + index + '.value'" :rules="{
      required: true, message: '内容不能为空', trigger: 'blur'
    }">
        <el-input type="textarea" :autosize="{ minRows: 4 }" placeholder="请输入建议" maxlength="100"
  show-word-limit v-model="content.value"></el-input>
      </el-form-item>
      <el-form-item class="btn">
        <div class="save-question" style="margin-right:20px;" @click="addDomain">
          <el-button :disabled="upForm.content.length>4" readonly v-throttle @click="">新增建议</el-button>
        </div>
        <el-button class="save-question" type="primary" @click="saveUpClick" v-throttle>提交</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</div>
</template>

<script>
//评审类型 4省级他评 2市级他评 3国家级督证 1机构自评
// 角色 1超级管理员 2国家 3市级 4机构 6省级
export default {
  name: "inspect-index",
  data() {
    return {
      //4省级他评 2市级他评 3国家督评 1机构自评
      // role: 2国家 6省 4机构 3市级 1超级管理员
      //submit_status: 0未提交，1已提交
      // examine_status：0未评审，1已评审，2评审中
      /* 可以修改的数组：
          1国家角色：已提交未评审完成的机构自评及国家督评
          2市级角色：未完成评审的市级他评
          3省级角色：未完成评审的省级他评
          4机构角色：未提交的的机构自评
      */ 
      editArr:[{
        role: 2,
        type: '1,3',
        submit_status: '1',
        examine_status: '0,2',
      },{
        role: 3,
        type: '2',
        submit_status: '0,1',
        examine_status: '0,2',
      },{
        role: 6,
        type: '4',
        submit_status: '0,1',
        examine_status: '0,2',
      },{
        role: 4,
        type: '1',
        submit_status: '0',
        examine_status: '0,1,2',
      }],
      cardList: [],
      score_all: 1000,
      loading: false,
      inspect_id: 1,
      inspect_status: '',
      questionnaire: '',
      upShow: false,
      opinion: '',
      role: '',
      backPage: '',
      upForm: {
        content: [{
          value: ''
        }],
      },
      ratio: '',
      org_name:'',
      object_all: '',
      submitLoading: false,

      pageType: '',

      queryReadonly: false,
    };
  },
  beforeRouteEnter(to, from, next){
    next(vm =>{
      if(from.name && from.params.type){
        vm.pageType = from.params.type
        localStorage.setItem('pageType',vm.pageType)
      }else if(from.name){
        if(from.name.indexOf('province') != -1){
          localStorage.setItem('pageType', 'province_review')
        }else if(from.name.indexOf('city') != -1){
          localStorage.setItem('pageType', 'city_review')
        }else if(from.name.indexOf('country') != -1){
          localStorage.setItem('pageType', 'country_review')
        }
      }
    })  
  },
  mounted() {
    this.queryReadonly = this.$route.query.readonly ? true : false
    this.inspect_id = this.$route.query.inspect_id;
    this.backPage = this.$route.query.backPage;
    sessionStorage.setItem('backPage', this.backPage || '');
    this.role = localStorage.getItem('role');
    this.init();
  },
  methods: {
    addDomain() {
      console.log('-----',this.upForm.content.length)
      if(this.upForm.content.length<5){
        console.log('add')
        this.upForm.content.push({
          value: '',
        });
      }else{
        this.$message({
          message: '最多可创建5条改进建议',
          type: "warning",
        });
      }
      
    },
    async init() {
      this.loading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/object/list",
        data: {
          inspect_id: this.inspect_id
        }
      });
      console.log(res);
      this.loading = false;
      if (res.code == 0) {
        let {
          list,
          score_all,
          object_all,
          inspect_type,
          inspect,
          opinion,
          questionnaire,
          ratio,
          org_name
        } = res.data;
        list.length && (this.cardList = list);
        if (inspect.type != 1) {
          inspect.examine_score = inspect.score;
        }
        this.org_name = org_name
        this.ratio = ratio
        this.score_all = score_all;
        this.inspect_status = inspect;
        this.opinion = opinion;
        this.questionnaire = questionnaire;

        this.object_all = object_all
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    goBack() {
      if (sessionStorage.getItem('backPage')) {
        this.$router.push(sessionStorage.getItem('backPage'));
      } else {
        this.$router.go(-1);
      }
    },
    goQuestionnaire() {
      this.$router.push({
        path: "/questionnaire",
        query: {
          inspect_id: this.inspect_id
        },
      });
    },
    startInspect(item) {
      let param = {
        object_pid: item.object_pid,
        inspect_id: this.inspect_id,
      }
      if(this.queryReadonly || this.readOnly){
        param.readonly = true
      }
      this.$router.push({
        path: "/question",
        query: param,
      });
    },
    saveUpClick() {
      let list = this.upForm.content.filter(e => {
        return e.value != ''
      })
      if (list.length == 0) {
        this.$message('请输入内容');
      } else {
        this.$confirm("提交后不可修改，确定提交吗", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(async () => {
            this.saveUp();
          })
          .catch(() => {

          });
      }

    },
    async saveUp() {
      let list = this.upForm.content.filter(e => {
        if (e.value) {
          return e
        }
      })
      let json = list.map(e => {
        if (e.value) {
          return {
            content: e.value
          }
        }
      })
      let res = await this.$api({
        method: "post",
        url: "/api/inspect/opinion_save",
        data: {
          inspect_id: this.inspect_id,
          contents_json: JSON.stringify(json)
        }
      });
      console.log(res);
      if (res.code == 0) {
        this.$message.success('保存成功');
        this.dialogClose();
        this.init();
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    dialogClose() {
      this.upShow = false;
    },
    goUp() {
      this.upShow = true;
    },
    // 非机构提交评审
    async eximainRelease() {
      if (this.isTest) {
        this.object_all = 0;
        this.inspect_status.examine_total_object = 0;
      }
      if (this.object_all - this.inspect_status.examine_total_object > 0) {
        this.$confirm(`有${this.object_all - this.inspect_status.examine_total_object}项没有评估，无法提交。`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "继续评估",
            type: "warning",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(() => {})
          .catch(() => {
            // this.editInspect(item.inspect_id)
          });
      } else {
        this.$confirm(`提交后不可修改，确定提交吗`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(() => {
            this.releaseApi(this.inspect_id);
          })
          .catch(() => {});
      }
    },
    toList(){
       //4省级他评 2市级他评 3国家级督证 1机构自评
      if(this.inspect_status.type == 1 && this.inspect_status.submit_status == 0){
        this.$router.push('/table/school_submitted');
      }else if(this.inspect_status.type == 1){
        this.$router.push('/table/school_reviewed');
      }else if(this.inspect_status.type == 2){
        this.$router.push('/table/city_reviewed');
      }else if(this.inspect_status.type == 3){
        this.$router.push('/table/country_reviewed');
      }else if(this.inspect_status.type == 4){
        this.$router.push('/table/province_reviewed');
      }
    },
    async releaseApi(id) {
      if (this.submitLoading) {
        return;
      }
      this.submitLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/release",
        data: {
          inspect_id: id,
          get_number: 0
        }
      });
      this.submitLoading = false;
      if (res.code == 0) {
        this.$message.success('提交成功');
        this.toList()
      } else if (res.code == -2) {
        this.$confirm(`您尚未填写问卷调研，填写后方可提交。`, "问卷调查", {
            confirmButtonText: "去填写",
            cancelButtonText: "取消",
            type: "error",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(() => {
            this.$router.push('/questionnaire?inspect_id=' + id)
          })
          .catch(() => {});
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    // 机构提交
    async schoolRelease() {
      if (this.submitLoading) {
        return;
      }
      this.submitLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/release",
        data: {
          inspect_id: this.inspect_id,
          get_number: 1
        }
      });
      this.submitLoading = false;
      if (res.code == 0) {
        this.$confirm(`提交后不可修改，确定提交吗`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(() => {
            this.releaseApi(this.inspect_id);
          })
          .catch(() => {});
      } else if (res.code == 1) {
        // if (this.isTest) {
        //   this.$confirm(`提交后不可修改，确定提交吗`, "提示", {
        //       confirmButtonText: "确定",
        //       cancelButtonText: "取消",
        //       type: "warning",
        //       center: true,
        //       customClass: "customConfirm",
        //       confirmButtonClass: "confirmButtonClass",
        //       cancelButtonClass: "cancelButtonClass",
        //     })
        //     .then(() => {
        //       this.releaseApi(this.inspect_id);
        //     })
        //     .catch(() => {});
        //   return
        // }
        this.$confirm(`有${res.data.not_number}项评估遗漏，无法提交。`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "继续评估",
            type: "warning",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(() => {
            this.releaseApi(this.inspect_id);
          })
          .catch(() => {
            // this.editInspect(id)
          });
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }

    },
  },
  computed: {
    isTest() {
      return location.href.indexOf('.test.') != -1 || location.href.indexOf(':808') != -1
    },
    inspectStatus() {
      if (this.inspect_status) {
        let {
          submit_status,
          examine_status,
          type
        } = this.inspect_status;
        let isSchoolType = localStorage.getItem("role") == 4;
        if (submit_status == 0 && examine_status == 0) {
          return 1
        } else if (submit_status == 1 && examine_status == 0) {
          return 2
        } else if (submit_status == 1 && examine_status == 2) {
          return 3
        } else if (submit_status == 1 && examine_status == 1) {
          return 4
        }
      } else {
        return 0
      }
    },
    readOnly() {
      if (this.inspect_status) {
        let index = this.editArr.findIndex(item=>{
          return item.role == localStorage.getItem("role") && 
                 item.type.indexOf(this.inspect_status.type) !=-1 &&
                 item.submit_status.indexOf(this.inspect_status.submit_status) != -1 &&
                 item.examine_status.indexOf(this.inspect_status.examine_status) != -1
        })
        return index == -1
        // return (this.inspect_status.submit_status == 1 && localStorage.getItem("role") == 4) ||
        //   (this.inspect_status.examine_status == 1) ||
        //   (this.inspect_status.type == 3 && localStorage.getItem("role") != 2) ||
        //   (this.inspect_status.type == 4 && localStorage.getItem("role") != 6) ||
        //   (this.inspect_status.type == 2 && localStorage.getItem("role") != 3) ||
        //   (this.inspect_status.type == 1 && localStorage.getItem("role") != 2 && localStorage.getItem("role") != 4)
      } else {
        return false
      }
    },
    
    // readOnly() {
    //   if (this.inspect_status) {
    //     return (this.inspect_status.submit_status == 1 && localStorage.getItem("role") == 4) ||
    //       (this.inspect_status.examine_status == 1 && localStorage.getItem("role") != 4) ||
    //       (this.inspect_status.type == 1 && this.inspect_status.examine_status == 0 && localStorage.getItem("role") != 4) || (this.inspect_status.submit_status == 1 && this.inspect_status.examine_status == 0 && localStorage.getItem("role") != 4) ||
    //       (this.inspect_status.examine_status == 1) ||
    //       (this.role == 2 && this.inspect_status.type == 2) || 
    //       (this.role == 1 && this.inspect_status.type == 2)
    //   } else {
    //     return false
    //   }
    // },
    isAdminType() {
      return this.inspect_status.type == 2 || this.inspect_status.type == 3 || this.inspect_status.type == 4
    },
    isSchoolType() {
      return this.inspect_status.type == 1
    },
    isAdminUser() {
      return localStorage.getItem("role") != 4
    },
    isSchoolUser() {
      return localStorage.getItem("role") == 4
    }
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.btn{
  ::v-deep .el-form-item__content{
    display: flex;
    align-items: center;
  }
}
.inspect-index {
  padding: 10px 4vw;
  min-height: calc(100vh - 150px);

  .save-question {
    width: 130px;
    margin: 30px auto 0;
    
  }

  .content {
    width: 100%;
    background: #fff;
    margin-top: 20px;
    padding: 0 3.9vw;

    .top {
      width: 100%;
      display: flex;
      font-size: 14px;
      line-height: 26px;
      text-align: left;
      position: relative;
      border-bottom: 1px solid #ebebeb;
      padding: 2.08vw 0;

      h3,
      p {
        margin: 0;
      }

      .t1 {
        margin-right: 3.44vw;
        flex: 0 0 150px;

        h3 {
          font-size: 18px;
          margin-bottom: 0.52vw;
        }
      }

      .t2 {
        margin-right: 3.44vw;
        flex: 0 0 240px;
        h3 {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }

      .t3 {
        flex: 0 0 auto;
        color: #666;
        text-indent: 28px;
      }

      .t4 {
        flex: 0 0 180px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        flex: auto;

        .questionnaire,
        .questionnaire2 {
          width: 100px;
          margin-top: 20px;
        }
      }
    }

    .bottom {
      padding: 3.13vw 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      box-sizing: unset;
      width: calc(100% + 30px);
      transform: translateX(-15px);

      .card {
        margin-bottom: 1.35vw;
        min-width: 298px;
        width: calc( 100% /3 - 30px );
        margin: 0 15px 26px;
        background: #ffffff;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        padding: 1.41vw 1.25vw;
        display: flex;
        cursor: pointer;

        &:hover {
          background: rgba(0, 131, 245, 0.5);
          border: 1px solid #0083F5;
          box-shadow: 0px 4px 10px 0px rgba(0, 131, 245, 0.19);

          h3 {
            color: 0083F5;
          }
        }

        .icon {
          flex: 0 0 40px;
          margin-right: 10px;

          img {
            width: 100%;
          }
        }

        .msg {
          flex: auto;
          font-size: 14px;
          padding-bottom: 20px;

          h3 {
            margin-bottom: 8px;
            font-size: 18px;
          }

          p {
            line-height: 25px;
          }

          .admin-inspect-card {
            display: flex;

            div {
              width: 60px;
            }

            div:first-of-type {
              width: 90px;
            }
          }
        }

        .btn {
          flex: 0 0 80px;
          display: flex;
          align-items: flex-end;
          color: #0083f5;
          font-size: 14px;

          span {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.inspect-index {
  display: block;
}
</style>
